<template>
  <div class="p-3">
    <div class="row">
      <div class="col-sm-9">
        <el-card class="box-card">
          <div v-if="$route.params.courseId">
            <div v-html="helper.displayI18nText($i18n.locale, course.content)" />
          </div>
          <div v-else>
            <router-view />
            <el-divider></el-divider>
            <div class="mt-4" style="display: flex; justify-content: center;">
              <router-link :to="{ name: 'EnrollmentNew' }">
                <el-button style="width:50%; min-width: 120px;" type="primary"
                  >Enroll Now!</el-button
                >
              </router-link>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col-sm-3">
        <!-- overview -->
        <el-card class="box-card">
          <div>
            <span>
              <h3 style="color: #42a16a;">
                <i class="fas fa-book"></i> Overview
              </h3>
            </span>
          </div>
          <el-divider></el-divider>
          <div style="font-size: 14px; font-weight: 600">
            <el-row>
              <el-col :span="12">
                <span>Length:</span>
              </el-col>
              <el-col :span="12">
                <span>{{ helper.displayI18nText($i18n.locale, this.course.length) }}</span>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="12">
                <span>Class size:</span>
              </el-col>
              <el-col :span="12">
                <span>{{ helper.displayI18nText($i18n.locale, this.course.class_size) }}</span>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="12">
                <span>Prerequisite:</span>
              </el-col>
              <el-col :span="12">
                <span>{{ helper.displayI18nText($i18n.locale, this.course.prerequisite) }}</span>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="12">
                <span>Placement test:</span>
              </el-col>
              <el-col :span="12">
                <span>{{
                  helper.displayI18nText($i18n.locale, this.course.has_placement_test)
                }}</span>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="12">
                <span>Level:</span>
              </el-col>
              <el-col :span="12">
                <span>{{ helper.displayI18nText($i18n.locale, this.course.level) }}</span>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="12">
                <span>Homework workload:</span>
              </el-col>
              <el-col :span="12">
                <span>{{
                  helper.displayI18nText($i18n.locale, this.course.homework_workload)
                }}</span>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="12">
                <span>Language:</span>
              </el-col>
              <el-col :span="12">
                <span>{{ helper.displayI18nText($i18n.locale, this.course.lang) }}</span>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <!-- Dates -->
        <el-card class="box-card mt-3">
          <div>
            <span>
              <h3 style="color: #42a16a;">
                <i class="fa fa-calendar-alt"></i> {{ `Dates (${thisYear})` }}
              </h3>
            </span>
          </div>
          <el-divider></el-divider>
          <div style="font-size: 14px; font-weight: 600">
            <el-row
              v-for="(courseSession, index) in filterEmptySessionCity(
                courseSessions || []
              )"
              :key="index"
              class="pb-3"
            >
              <el-col :span="8" :lg="6" :md="24">
                <span>{{ courseSession.city }}:</span>
              </el-col>
              <el-col :span="16" :lg="18" :md="24">
                <ul class="sessions">
                  <li
                    :key="session"
                    v-for="(session, sessionIndex) in courseSession.sessions"
                  >
                    <div class="sessionRow">
                      <div>{{ session.started_on }}</div>
                      <div class="middleDateSymbol">-</div>
                      <div>{{ session.ended_on }}</div>
                      <div>{{ `(第${getChineseNumber(sessionIndex)}梯次)` }}</div>
                    </div>
                  </li>
                </ul>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <!-- Discount -->
        <el-card class="box-card mt-3">
          <div>
            <span>
              <h3 style="color: #42a16a;">
                <i class="fas fa-dollar-sign"></i> Discount
              </h3>
            </span>
          </div>
          <div style="font-size: 14px; font-weight: 600">
            <template v-if="(discountList[$route.name] || []).length > 0">
              <el-divider></el-divider>
              <h6 style="font-weight:bold;">Group discount:</h6>
              <el-row
                v-for="(discount, index) in discountList[$route.name]"
                :key="index"
                class="pb-3"
              >
                <el-col :span="24">
                  <span>{{ discount }}</span>
                </el-col>
              </el-row>
            </template>
            <template v-if="filterExpiredDate(earlyBirdList[$route.name] || []).length > 0">
              <el-divider></el-divider>
              <h6 style="font-weight:bold;">Early-Bird Discount:</h6>
              <el-row
                v-for="(elem, index) in filterExpiredDate(earlyBirdList[$route.name])"
                :key="index"
                class="pb-3"
              >
                <el-col :span="24">
                  <span>{{ elem.content }}</span>
                </el-col>
              </el-row>
            </template>
          </div>
        </el-card>

        <!-- Locations -->
        <el-card class="box-card mt-3">
          <div>
            <span>
              <h3 style="color: #42a16a;">
                <i class="fas fa-map-marker-alt"></i> Locations
              </h3>
            </span>
          </div>
          <el-divider></el-divider>
          <div style="font-size: 14px; font-weight: 600">
            <el-row
              v-for="(loc, index) in locationList"
              :key="index"
              class="pb-3"
            >
              <el-col :span="8" :md="12">
                <span>{{ loc.city }}:</span>
              </el-col>
              <el-col :span="16" :md="12">
                <span>{{ loc.place }}</span>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <!-- Enroll -->
        <el-card class="box-card mt-3">
          <div>
            <span>
              <h3 style="color: #42a16a;">
                <i class="fas fa-graduation-cap"></i> Enroll
              </h3>
            </span>
          </div>
          <el-divider></el-divider>
          <div style="font-size: 14px; font-weight: 600">
            <router-link :to="{ name: 'EnrollmentNew' }">
              <el-button style="width:100%" type="success">
                Enroll Now!
              </el-button>
            </router-link>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@ivy-way/material";
import courseApi from "@/apis/course";

export default {
  computed: {
    helper() {
      return helper;
    },
    thisYear() {
      return new Date().getFullYear();
    }
  },
  data() {
    return {
      course: {},
      courseSessions: [
        {
          city: "台北",
          sessions: []
        },
        {
          city: "新竹",
          sessions: []
        },
        {
          city: "台中",
          sessions: []
        },
        {
          city: "台南",
          sessions: []
        },
        {
          city: "高雄",
          sessions: []
        },
        {
          city: "線上",
          sessions: []
        }
      ],
      discountList: {
        SATCourse: [
          "2 students: $2,000 NT off per student",
          "3-4 students: $3,000 NT off per student",
          "5+ students: $5,000 NT off per student"
        ]
      },
      earlyBirdList: {
        SATCourse: [
          { date: "2021/01/30", content: "$12,000 NT off if paid before 01/30" },
          { date: "2021/03/30", content: "$8,000 NT off if paid before 03/30" },
          { date: "2021/04/30", content: "$4,000 NT off if paid before 04/30" },
          { date: "2021/05/30", content: "$2,000 NT off if paid before 05/30" }
        ],
        TOEFLCourse: [
          { date: "2021/03/31", content: "$8,000 NT off if paid before 03/31" },
          { date: "2021/04/30", content: "$4,000 NT off if paid before 04/30" },
          { date: "2021/05/31", content: "$2,000 NT off if paid before 05/31" }
        ]
      },
      locationList: [
        {
          city: "台北",
          place: "台北市中正區許昌街24號4樓"
        },
        {
          city: "新竹",
          place: "TBD"
        },
        {
          city: "台南",
          place: "TBD"
        },
        {
          city: "台中",
          place: "TBD"
        },
        {
          city: "高雄",
          place: "TBD"
        },
        {
          city: "南投",
          place: "TBD"
        },
        {
          city: "上海浦東",
          place: "TBD"
        },
        {
          city: "上海浦西",
          place: "TBD"
        },
        {
          city: "杜拜",
          place: "TBD"
        }
      ]
    };
  },
  async created() {
    if (this.$route.params.courseId) {
      const { course } = await courseApi.fetchCourse(
        this.$route.params.courseId
      );
      this.course = course;
    } else {
      const { course } = await courseApi.fetchCourseByPageUrl(this.$route.name);
      this.course = course;
    }
    const removeYear = date => {
      const [_y, m, d] = date.split("-");
      return `${m}/${d}`;
    };
    let courseSessions = {};
    this.course.course_sessions
      .filter(session =>
        session.is_visible && session.started_on && session.ended_on
      )
      .forEach(session => {
        courseSessions[session.city] = [
          ...(courseSessions[session.city] || []),
          {
            started_on: removeYear(session.started_on),
            ended_on: removeYear(session.ended_on)
          }
        ];
      });
    this.courseSessions = this.courseSessions.map(({ city }) => ({
      city,
      sessions: courseSessions[city]
    }));
  },
  methods: {
    filterExpiredDate(discounts) {
      return discounts.filter(({ date }) => (
        moment(date).isAfter(moment())
      ));
    },
    filterEmptySessionCity(courseSessions) {
      return courseSessions.filter(({ sessions }) => (sessions || []).length > 0);
    },
    getChineseNumber(index) {
      const chineseNumber = [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十"
      ];
      return chineseNumber[index];
    }
  }
};
</script>

<style lang="scss" scoped>
.headerFont {
  font-size: 32px;
  color: #42a16a;
  font-weight: 600;
}
.fa-icon {
  color: #42a16a;
  font-size: 72px;
}
.sessions {
  margin-block-end: 0;
}
.sessionRow {
  display: grid;
  grid-template-columns: 46px 10px 46px 80px;
}
.sessionRow > div {
  justify-self: center;
}
::v-deep .el-table {
  color:#212121 !important;
}
</style>
